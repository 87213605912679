.carousel .control-dots {
  margin-bottom: 50px;
}
.menu-carousel {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 2px 4px 12px rgba(105, 107, 107, 0.3);
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.menu-search {
  background-color: #ffffff;
  border-radius: 10px;

  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.ds-select {
  border: 0.5px solid #3ba3a5;
}
.ds-select:focus {
  border: 1px solid #3ba3a5;
}
.btn-search {
  background-color: #3ba3a5;
  color: #ffffff;
  padding: 3px 0;
  border-radius: 15px;
}
.content-main {
  left: 50%;
  transform: translateX(-50%);
}
.css-bor0 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  width: 100%;
}
.text-reponsive-news {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
@media (max-width: 1024px) {
  .text-reponsive-news {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
@media (max-width: 1024px) {
  .text-reponsive {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.title-reponsive {
  min-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.slick-track #img-slick {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.repon-img {
  transform: scale(100%);
}
.repon-img:hover {
  transform: scale(103%);
}
.point-show {
  animation: 1s fadeShow linear;
}
.point-hide {
  animation: 1s fadeHide linear;
}

@keyframes fadeHide {
  0% {
    transform: scale(95%);
  }
  100% {
    transform: scale(100%);
  }
}

@keyframes fadeShow {
  0% {
    transform: scale(95%);
  }
  100% {
    transform: scale(100%);
  }
}
.content-popup-condition {
  position: relative;
  top: 25%;
  width: 100%;
  max-width: 400px;
  transform: translateY(-50%);
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}
.content-popupgame {
  background-color: #ffffff;
}
.img-title {
  top: -5%;
  left: 0%;
  display: flex;
  justify-content: center;
}
.scroll-ocr {
  position: fixed;
  bottom: 60px;
  right: 40px;
  animation: scrollOCR 0.8s linear;
  border-radius: 60px;
  background-color: #ffffff;
}
@media (max-width: 600px) {
  .scroll-ocr {
    right: 20px;
  }
  .barcode-repon {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.anima-scroll-top {
  animation: fadeTop linear 1s infinite;
}
@keyframes fadeTop {
  0% {
    transform: translateY(7px);
    opacity: 0;
  }
  50% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(-6px);
    opacity: 0;
  }
}
.border-ani {
  position: relative;
  font-size: 18px;
}
.border-ani:hover:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  border-bottom: solid 5px #13a5a5;
  animation: border_anim 0.3s linear forwards;
}

@keyframes border_anim {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
.border-1 {
  border: 1px solid #13a5a5;
}
.border-1:hover {
  animation: fadeHover 0.3s linear forwards;
}
@keyframes fadeHover {
  0% {
    border-color: #13a5a5;
  }
  100% {
    border-color: #dc5a7b;
  }
}
.border-out1 {
  border: 2px solid #13a5a5;
}
.border-out2 {
  border: 6px solid #13a5a5;
}
.border-out3 {
  box-shadow: 0px 0px 4px -0.1px rgb(10, 189, 202);
}
.border-out4 {
  border: 2px solid #13a5a5;
}
