.bg-title-right {
  background-image: url(../assets/image/bg.svg);
  background-repeat: no-repeat;
  position: relative;
  left: 0px;
}
.bg-right {
  background-image: url(../assets/image/bg-right.svg);
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0px;

  bottom: -16px;
}
@media (max-width: 600px) {
  .bg-right {
    background-image: url(../assets/image/bg-right.svg);
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0px;

    bottom: 0px;
  }
}
.bg-cart {
  background-image: linear-gradient(#ffff63, #fec201);
}
