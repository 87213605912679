.font-bold {
  font-family: "SVN-GothamBold";
}
.font-light {
  font-family: "SVN-GothamLight";
}
.font-regular {
  font-family: "SVN-GothamRegular";
}
.font-book {
  font-family: "SVN-GothamBook";
}
@font-face {
  font-family: "SVN-GothamBook";
  src: url(../font/SVN-GothamBook.otf);
}
@font-face {
  font-family: "SVN-GothamLight";
  src: url(../font/SVN-GothamLight.ttf);
}
@font-face {
  font-family: "SVN-GothamBold";
  src: url(../font/SVN-GothamBold.ttf);
}
@font-face {
  font-family: "SVN-GothamRegular";
  src: url(../font/SVN-GothamRegular.otf);
}
