.font-gothamBold {
  font-family: "GothamBold";
}
.font-gothamUltra {
  font-family: "SVN-GothamUltra";
}
.font-gothamRegular {
  font-family: "SVN-GothamRegular";
}
.font-gothamLight {
  font-family: "SVN-GothamLight";
}
.font-gothamBook {
  font-family: "SVN-GothamBook";
}
.font-place {
  font-size: 13px;
  color: #ee4c7e;
}
.font-title-place {
  font-size: 14px;
  color: grey;
}
@font-face {
  font-family: "GothamBold";
  src: url("./assets/font/SVN-GothamBold.ttf");
}
@font-face {
  font-family: "SVN-GothamRegular";
  src: url("./assets/font/SVN-GothamRegular.otf");
}
@font-face {
  font-family: "SVN-GothamUltra";
  src: url("./assets/font/SVN-GothamUltra.otf");
}
@font-face {
  font-family: "SVN-GothamLight";
  src: url("./assets/font/SVN-GothamLight.ttf");
}
@font-face {
  font-family: "SVN-GothamBook";
  src: url("./assets/font/SVN-GothamBook.otf");
}

.nav {
  display: flex;
  align-items: center;
  height: 90px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 0px rgb(180, 173, 173);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  animation: framNavbar 0.6s linear;
}

@keyframes framNavbar {
  from {
    opacity: 0;
    transform: translateY(-80%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
.nav-color {
  display: flex;
  align-items: center;
  height: 90px;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.nav__list {
  display: flex;
  list-style: none;
}

.nav__link {
  text-decoration: none;
  color: #fff;
  /* background-color: #fff; */
  margin-right: 10px;
  font-size: 17px;
  font-weight: 450;
}

h1 {
  line-height: 2rem;
}

article {
  padding: 0 16px;
  line-height: 1.6rem;
}

article img {
  width: 640px;
  max-width: 100%;
  display: block;
  margin: 32px auto;
}
.nav_bars-btn {
  width: 28px;
  height: 28px;
  color: #fff;
  display: none;
  /* margin-left: 8px; */
  position: absolute;
  right: 15px;
}

.logo_mvc-child-mobile {
  position: absolute;
  top: 22px;
  width: 160px;
  left: 30px;
}
.ani-icon-zalo {
  animation: aniZalo linear 0.7s alternate-reverse infinite;
}
.scroll-ocr {
  position: fixed;
  bottom: 60px;
  right: 40px;
  animation: scrollOCR 0.8s linear;
  /* border: 1px solid #e5557f; */
  border-radius: 60px;
  background-color: #ffffff;
}
@keyframes scrollOCR {
  from {
    transform: translateX(400%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes aniZalo {
  from {
    transform: rotate(-15deg);
  }
  to {
    transform: rotate(15deg);
  }
}
@media (max-width: 1024px) {
  .scroll-ocr {
    right: 20px;
  }
  .nav {
    height: 62px;
  }
  .logo_mvc-child-mobile {
    top: 14px;
    width: 120px;
    left: 30px;
  }
  .nav-color {
    height: 55px;
  }
}
.nav_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: none;
  animation: fadeIn linear 0.4s;
}
.nav__mobile {
  position: fixed;
  overflow: hidden;
  top: 1px;
  right: 0;
  /* bottom: 25%; */
  width: 270px;
  margin-bottom: 10px;
  /* bottom: 10px; */
  background-color: #f9fdfa;
  transform: translateX(100%);
  opacity: 0;
  border-top-left-radius: 68px;
  border-bottom-left-radius: 68px;
  transition: transform ease-out 0.5s, opacity ease-out 0.5s;
  box-shadow: 0px 5px 30px 1px #333333;
}
.nav__mobile-link {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 8px 0;
  /* font-size: 1.4rem; */
  font-weight: 490;
  margin-left: 40px;
}

.nav__mobile-list {
  margin-top: 80px;
  padding: 0;
}
.logo_mvc-nav {
  margin-top: 28px;
  width: 140px;
  position: absolute;
  left: 24%;
}
.nav__link {
  color: #333;
}
.nav_mobile-close {
  color: #666;
  position: absolute;
  width: 1.4rem;
  top: 1rem;
  right: 1rem;
}
.nav__mobile-list > li {
  list-style: none;
  border-top: 0.5px solid;
  border-image: linear-gradient(to right, #3aa3a5, #e5557f) 1;
}
.nav__mobile-list > li:hover {
  background-color: #add7bc;
}
.nav__mobile-list > li:nth-last-child(1) {
  border-bottom: 0.1px solid;
  border-image: linear-gradient(to right, #3aa3a5, #e5557f) 1;
}
.nav__mobile-li {
  margin-left: 50px;
}
.nav_input:checked ~ .nav_overlay {
  display: block;
  /* color: red; */
}

.nav-mobile-input:checked ~ .nav__mobile {
  transform: translateX(0%);
  opacity: 1;
}
.nav__list .nav__link {
  list-style-type: none;
}
.nav__list {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translate(-50%, 0);
  /* right: 100px; */
}
.logo_mvc-child {
  position: absolute;
  top: 5px;
  width: 100px;
  left: 100px;
}
.font-regular {
  font-family: "SVN-Gotham-Regular";
}
.li-navbar:hover {
  cursor: pointer;
  background-color: #3aa3a5;
}

@media (max-width: 1023px) {
  .nav_bars-btn {
    display: block;
  }
  .nav__pc {
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .map-tech {
    display: none;
  }
}
.text-table-ocr {
  font-size: 16px;
}
@media (max-width: 600px) {
  .text-table-ocr {
    font-size: 10px !important;
  }
}

.border-title:hover {
  cursor: pointer;
  color: #3aa3a5;
}
.text-title {
  color: #3aa3a5;
}
.content img {
  width: 100%;
}
