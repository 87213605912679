.text-header {
  font-size: 36px;
}
@media (max-width: 1024px) {
  .text-header {
    font-size: 20px;
  }
}
.bg-footer {
  background-color: #13a5a5;
}
.dropdown:hover .dropdown-ani {
  display: block;
  animation: show 1s linear;
}
.dropdown-ani {
  display: none;
}
@keyframes show {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
