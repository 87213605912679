.categ-place {
  padding: 15px;
  border: 0px solid #333;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 8px 5px 25px 1px rgba(0, 0, 0, 0.25);
  position: -webkit-sticky;
  position: sticky;
  top: 98px;
}
.bor-img {
  border-radius: 40px;
  width: 40px;
  height: 40px;
  background-color: #b6d3d3;
}
/* @media (min-width: 1024px) {
  .btn-ut:hover {
    background-color: #e5567f;
    animation: fadeIn 1s ease;
  }
} */
.div-ne ul li {
  list-style-type: disc;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.title-header {
  font-size: 21px;
  color: #3aa3a4;
  border-bottom: 1px solid #dfdddd;
  margin-right: 30px;
  padding-bottom: 20px;
}
.title-header-staff {
  font-size: 21px;
  color: #3aa3a4;
  padding-bottom: 20px;
}
.description-sale {
  font-size: 15px;
  color: #000000;
}
.ca .ci {
  list-style-type: disc;
}
.title-list {
  font-size: 13px;
}
.description-list {
  font-size: 14px;
}
.list-border {
  padding: 8px;
  border-radius: 8px;
  box-shadow: 8px 5px 25px 1px rgba(0, 0, 0, 0.25);
}
.bg-tab {
  padding: 7px;
  border-radius: 8px;
  box-shadow: 5px 3px 10px 1px rgba(0, 0, 0, 0.25);
}
.tab button {
  border: none;
  cursor: pointer;
  text-align: center;
}
.tab button:hover {
  background-color: #e5567f;
  height: 40px;
  border-radius: 10px;
  width: 90%;
  color: white;
}
.tab button:disabled {
  height: 40px;
  border-radius: 10px;
  background-color: #3aa3a4;
  width: 90%;
  color: white;
}
.new-animation {
  animation: flatNew 0.8s linear infinite alternate-reverse;
}
@keyframes flatNew {
  from {
    scale: 0.8;
  }
  to {
    scale: 1;
  }
}
